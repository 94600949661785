import {
  api,
  createCrudService,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/workingHours', api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  helpers.updateOptionsRefs(ctx.centerOptions, ctx.centersByCenterType)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.centerType.options = ctx.centerTypeOptions
  fieldsById.center.options = ({ itemData }) => {
    const centerTypeId = itemData?.centerType?.value
    const options =
      centerTypeId != null
        ? ctx.centersByCenterType[centerTypeId]
        : ctx.centerOptions
    return options ?? []
  }

  helpers.updateOptionToNullOnValueChange(
    fieldsById.centerType,
    fieldsById.center
  )
}

export default service
